import { createContext, useCallback, useContext } from 'react'
import { getAddItemProduct } from 'helpers'
import { useIntl } from 'intl'
import { getAnalyticsContext } from 'analytics'
import logger from 'logger'
import { useQueueAddItems } from 'modules/queue'
import { useProductsBy } from 'modules/products'
import { useProductsInQueueContext } from '../ProductsInQueueProvider/ProductsInQueueProvider'

/**
 * This context helps to promote specific products on landing pages
 */
type LandingContextValue = {
  productsToAddToQueue: { sku: string[] }
}

const LandingContext = createContext<LandingContextValue>(null)

const LandingContextProvider = LandingContext.Provider


export const useLandingProductsToAddToQueue = () => {
  const context = useContext(LandingContext)
  const skus = context?.productsToAddToQueue?.sku || []

  return useProductsBy('sku', skus)
}

export const useLandingOnGetStartedClick = ({ placement }: { placement?: string } = {}) => {
  const intl = useIntl()
  const productsToAddToQueue = useLandingProductsToAddToQueue()
  const [ submit ] = useQueueAddItems()
  const { updateCount } = useProductsInQueueContext()

  return useCallback(async () => {
    const { page, pageType } = getAnalyticsContext()

    const products = productsToAddToQueue?.products?.map((product): QueueModule.AddItemInput => {
      return {
        force: true,
        position: 'FIRST',
        product: getAddItemProduct({
          intl,
          product,
          tradingItem: product.tradingItems?.items?.find((item) => item.section === 'Subscription'),
        }),
        metadata: {
          page,
          pageType,
          placement,
        },
      }
    })

    if (products?.length) {
      try {
        await submit(products, {
          withNotification: false,
          withNonAuthRedirect: false,
          withErrorModals: false,
        })
      }
      catch (error) {
        logger.warn(error, 'Can\'t add promoted product to users queue')
      }
      finally {
        updateCount()
      }
    }
  }, [ productsToAddToQueue?.products, intl, placement, submit, updateCount ])
}

export default LandingContextProvider
